








































































import { useProperties } from '@/composition/properties'
import PropertyCardSkeleton from '@/components/PropertyCardSkeleton.vue'
import PropertyCard, { PropertyCardMode } from '@/components/PropertyCard.vue'
import { PropertyViewModel } from '@/services/modules/property'
import { breakpointsRecords } from '@/design/system/helpers'
import { Swiper } from 'vue-awesome-swiper'
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'

export default defineComponent({
  name: 'ListingsSection',

  components: {
    PropertyCardSkeleton,
    PropertyCard,
  },

  props: {
    estateProperty: {
      type: Object as PropType<PropertyViewModel | null>,
      default: null,
    },

    agentKey: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },

  setup(props, context) {
    const rowsPerPagePropertyList = 9
    const propertyListPropertyMode = ref<PropertyCardMode>('grid')

    const { data: propertyList, isLoading: isLoadingPropertyList } =
      useProperties({
        pageNumber: 1,
        rowsPerPage: rowsPerPagePropertyList,
        excludePropertyKey: props.estateProperty?.propertyKey,
        categoryId: props.estateProperty?.categoryViewModel.categoryId,
        agentKey: props.agentKey || undefined,
        bedroom: props.estateProperty?.bedroom,
        propertyTypeList: props.estateProperty
          ? [{ id: props.estateProperty.typeViewModel.typeId }]
          : [],
        propertyAreaList: [],
        isDeleted: false,
      })

    const propertyListSwiper = ref<InstanceType<typeof Swiper> | null>(null)

    const propertyListSwiperAllowTouchMove = ref(false)

    const propertyListSwiperOptions = computed(() => ({
      slidesPerView: 'auto',
      allowTouchMove: propertyListSwiperAllowTouchMove.value,
      observer: true,
      observeParents: true,
    }))

    const allowTouchMoveMql = window.matchMedia(
      `(min-width: ${breakpointsRecords.lg}px)`
    )

    const allowTouchMoveMqlListener = () => {
      propertyListSwiperAllowTouchMove.value = !allowTouchMoveMql.matches
    }

    allowTouchMoveMqlListener()

    allowTouchMoveMql.addEventListener('change', allowTouchMoveMqlListener)

    const propertyListSlidesPerView = 3
    const propertyListCurrentPage = ref(1)
    const propertyListCurrentSlideIndex = ref(0)
    const propertyListCount = computed(() => propertyList.value.length)
    const propertyListPageCount = computed(() =>
      Math.ceil(propertyListCount.value / propertyListSlidesPerView)
    )
    const propertyListPaginationLabel = computed(
      () => `${propertyListCurrentPage.value}/${propertyListPageCount.value}`
    )

    function onPropertyListPreviousClick() {
      if (!propertyListSwiper.value) return
      const isFirstPage = propertyListCurrentPage.value === 1
      propertyListCurrentSlideIndex.value = isFirstPage
        ? propertyListCount.value - 1
        : propertyListCurrentSlideIndex.value - propertyListSlidesPerView
      propertyListSwiperSlideTo(propertyListCurrentSlideIndex.value)
      propertyListCurrentPage.value = isFirstPage
        ? propertyListPageCount.value
        : propertyListCurrentPage.value - 1
    }

    function onPropertyListNextClick() {
      if (!propertyListSwiper.value) return
      const isLastPage =
        propertyListCurrentPage.value === propertyListPageCount.value
      propertyListCurrentSlideIndex.value = isLastPage
        ? 0
        : propertyListCurrentSlideIndex.value + propertyListSlidesPerView
      propertyListSwiperSlideTo(propertyListCurrentSlideIndex.value)
      propertyListCurrentPage.value = isLastPage
        ? 1
        : propertyListCurrentPage.value + 1
    }

    watch(
      propertyListSwiperOptions,
      (newValue, oldValue) => {
        if (!propertyListSwiper.value) return
        propertyListSwiper.value.$swiper.allowTouchMove =
          newValue.allowTouchMove
        if (oldValue.allowTouchMove) {
          propertyListCurrentPage.value = 1
          propertyListSwiperSlideTo(1)
        }
      },
      {
        deep: true,
      }
    )

    function propertyListSwiperSlideTo(slideIndex: number) {
      if (!propertyListSwiper.value) return
      propertyListSwiper.value.$swiper.slideTo(slideIndex, 300, false)
    }

    function onShare(estateProperty: PropertyViewModel) {
      context.emit('share', estateProperty)
    }

    return {
      propertyList,
      isLoadingPropertyList,
      propertyListCount,
      propertyListPageCount,
      rowsPerPagePropertyList,
      propertyListPropertyMode,
      propertyListSwiper,
      propertyListSwiperOptions,
      propertyListCurrentPage,
      propertyListPaginationLabel,
      onPropertyListPreviousClick,
      onPropertyListNextClick,
      onShare,
    }
  },
})
