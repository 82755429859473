











































import SiteSection from '@/components/SiteSection.vue'
import ContactFormSkeleton from '@/components/ContactFormSkeleton.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AgentSingleSkeleton',

  components: {
    SiteSection,
    ContactFormSkeleton,
  },
})
