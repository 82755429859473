































































































































import SiteSection from '@/components/SiteSection.vue'
import ContactForm from '@/components/ContactForm.vue'
import ContactFormSkeleton from '@/components/ContactFormSkeleton.vue'
import ListingsSection from '@/components/ListingsSection.vue'
// import AgentReviewsSection from '@/components/AgentReviewsSection.vue'
import PropertyShareModal from '@/components/PropertyShareModal.vue'
import { AgentViewModel } from '@/services/modules/agent'
import { PropertyViewModel } from '@/services/modules/property'
import { formatPhoneNumber } from '@/utils/phone'
import { address } from '@/app.config.json'
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'
import Disqus from '@/components/DisqusForm.vue'

export default defineComponent({
  name: 'AgentProfile',

  components: {
    SiteSection,
    ContactForm,
    ContactFormSkeleton,
    ListingsSection,
    // AgentReviewsSection,
    PropertyShareModal,
    Disqus,
  },

  props: {
    agent: {
      type: Object as PropType<AgentViewModel>,
      required: true,
    },
  },

  setup(props) {
    const agentPhone = computed(() =>
      formatPhoneNumber(props.agent.phone || '')
    )

    const agentCellPhone = computed(() =>
      formatPhoneNumber(props.agent.cellPhone)
    )

    const selectedEstateProperty = ref<PropertyViewModel | null>(null)
    const isShareModalOpen = ref(false)

    watch(
      () => selectedEstateProperty.value,
      (newValue) => {
        isShareModalOpen.value = Boolean(newValue)
      }
    )

    return {
      agentPhone,
      agentCellPhone,
      address,
      selectedEstateProperty,
      isShareModalOpen,
    }
  },
})
