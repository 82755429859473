import { AxiosInstance } from 'axios'
import { api } from '../client'
import { serviceUrl } from '../utils'

const controller = serviceUrl('agent')

function createAgentService(client: AxiosInstance) {
  return {
    get(params: AgentModel) {
      return client.get<AgentViewModel[]>(controller.action('get'), {
        params,
      })
    },
  }
}

export const agentService = createAgentService(api.base)

export interface AgentModel {
  languageId?: string
  agentKey?: number
  positionId?: string
}

export interface AgentViewModel {
  agentId: string
  agentKey: number
  firstName: string
  lastName: string
  image: string
  phone: string | null
  cellPhone: string
  email: string
  positionViewModel: PositionViewModel
}

export interface PositionViewModel {
  positionId: string
  positionKey: number
  name: string
  title: string
  description: string
}
