





import AgentSingleSkeleton from '@/components/AgentSingleSkeleton.vue'
import AgentSingle from '@/components/AgentSingle.vue'
import { useAgents } from '@/composition/agents'
import { AgentViewModel } from '@/services/modules/agent'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AgentProfile',

  components: {
    AgentSingleSkeleton,
    AgentSingle,
  },

  props: {
    agentKey: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { data, isLoading } = useAgents({
      agentKey: props.agentKey,
    })

    const agent = computed<AgentViewModel | undefined>(() => data.value[0])

    return {
      agent,
      isLoading,
    }
  },
})
