var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{staticClass:"grid gap-4 p-4 bg-gray-100"},[_c('BaseHeading',{staticClass:"text-center text-lg font-semibold",attrs:{"level":2}},[_c('i18n',{attrs:{"tag":false,"path":"title"}})],1),_c('ValidationObserver',{attrs:{"slim":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{ref:"form",staticClass:"grid gap-4 md:grid-cols-2",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":_vm.firstNameLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInputText',{attrs:{"id":"first-name","placeholder":_vm.firstNamePlaceholder,"autocomplete":"given-name","type":"text"},model:{value:(_vm.model.firstName),callback:function ($$v) {_vm.$set(_vm.model, "firstName", $$v)},expression:"model.firstName"}}),_c('BaseFieldMessage',{attrs:{"id":"first-name-message","message":errors[0],"reserve-message-space":false,"tone":"critical"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.lastNameLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInputText',{attrs:{"id":"last-name","placeholder":_vm.lastNamePlaceholder,"autocomplete":"family-name","type":"text"},model:{value:(_vm.model.lastName),callback:function ($$v) {_vm.$set(_vm.model, "lastName", $$v)},expression:"model.lastName"}}),_c('BaseFieldMessage',{attrs:{"id":"last-name-message","message":errors[0],"reserve-message-space":false,"tone":"critical"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.emailLabel,"rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInputText',{attrs:{"id":"email","placeholder":_vm.emailPlaceholder,"autocomplete":"email","type":"email"},model:{value:(_vm.model.emailAddress),callback:function ($$v) {_vm.$set(_vm.model, "emailAddress", $$v)},expression:"model.emailAddress"}}),_c('BaseFieldMessage',{attrs:{"id":"email-message","message":errors[0],"reserve-message-space":false,"tone":"critical"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.phoneLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInputText',{directives:[{name:"mask",rawName:"v-mask",value:("(###) ###-####"),expression:"`(###) ###-####`"}],attrs:{"id":"phone","placeholder":_vm.phonePlaceholder,"autocomplete":"tel","type":"tel"},model:{value:(_vm.model.phoneNumber),callback:function ($$v) {_vm.$set(_vm.model, "phoneNumber", $$v)},expression:"model.phoneNumber"}}),_c('BaseFieldMessage',{attrs:{"id":"phone-message","message":errors[0],"reserve-message-space":false,"tone":"critical"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"md:col-span-2",attrs:{"name":_vm.messageLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseTextarea',{attrs:{"id":"message","placeholder":_vm.messagePlaceholder,"autocomplete":"off","type":"text"},model:{value:(_vm.model.message),callback:function ($$v) {_vm.$set(_vm.model, "message", $$v)},expression:"model.message"}}),_c('BaseFieldMessage',{attrs:{"id":"message-message","message":errors[0],"reserve-message-space":false,"tone":"critical"}})]}}],null,true)}),_c('BaseBox',{staticClass:"text-right md:col-span-2"},[_c('BaseButton',{attrs:{"loading":_vm.isSubmitting,"tone":"tertiary","type":"submit"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"send"}})]},proxy:true},{key:"loading-text",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"submitButtonLoading"}})]},proxy:true}],null,true)})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }