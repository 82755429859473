import { Ref, ref } from '@vue/composition-api'

export function useState<T>(
  initialState: T
): [state: Readonly<Ref<T>>, setter: (newState: T) => void] {
  const state = ref(initialState) as Ref<T>
  const setState = (newState: T) => {
    state.value = newState
  }
  return [state, setState]
}
