















































































































































import { useContact } from '@/composition/contact'
import i18n from '@/setup/i18n'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'ContactForm',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    to: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const form = ref<HTMLFormElement | null>(null)

    const firstNameLabel = computed(() =>
      i18n.t('contactFields.firstName.label')
    )
    const firstNamePlaceholder = computed(() =>
      i18n.t('contactFields.firstName.placeholder')
    )

    const lastNameLabel = computed(() => i18n.t('contactFields.lastName.label'))
    const lastNamePlaceholder = computed(() =>
      i18n.t('contactFields.lastName.placeholder')
    )

    const emailLabel = computed(() => i18n.t('contactFields.email.label'))
    const emailPlaceholder = computed(() =>
      i18n.t('contactFields.email.placeholder')
    )

    const phoneLabel = computed(() => i18n.t('contactFields.phone.label'))
    const phonePlaceholder = computed(() =>
      i18n.t('contactFields.phone.placeholder')
    )

    const messageLabel = computed(() => i18n.t('contactFields.message.label'))
    const messagePlaceholder = computed(() =>
      i18n.t('contactFields.message.placeholder')
    )

    const { model, submit, isSubmitting } = useContact({ form })

    watch(
      () => props.to,
      () => {
        model.to = props.to
      },
      {
        immediate: true,
      }
    )

    return {
      model,
      submit,
      isSubmitting,
      form,
      firstNameLabel,
      firstNamePlaceholder,
      lastNameLabel,
      lastNamePlaceholder,
      emailLabel,
      emailPlaceholder,
      phoneLabel,
      phonePlaceholder,
      messageLabel,
      messagePlaceholder,
    }
  },
})
