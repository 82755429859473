<template>
  <div>
    <div class="comments">
      <Disqus shortname="viverealestate" />
    </div>
  </div>
</template>

<script>
import { Disqus } from 'vue-disqus'

export default {
  name: 'DisqusForm',
  components: {
    Disqus,
  },

  data: () => ({
    pageConfig: {
      api_secret:
        '5QGyvkrJwV1mLYnCExu8b0gJM7H2zoRmGmZiS22OnL63xTbz0Qpom5kQg1WMAaVm',
    },
  }),
}
</script>
