import { AgentModel, agentService } from '@/services/modules/agent'
import { currentLanguage } from '@/utils/language'
import { computed } from '@vue/composition-api'
import { useService } from './service'

export function useAgents(model?: AgentModel) {
  const request = computed<AgentModel>(() => ({
    languageId: currentLanguage.value.guid,
    ...model,
  }))

  return useService(request, () => agentService.get(request.value))
}
